import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import MenuCard from './pages/MenuCard/MenuCard';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './App.scss';

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane when="false" contentId="main">
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              {/* <Home /> */}
              <MenuCard />
            </Route>
            <Route path="/Menu" exact={true}>
              <MenuCard />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>      
    </IonApp>
  );
};

export default App;
