import React from "react";
import { IonButton } from "@ionic/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./Header.scss";
import { useLocation } from "react-router";

const Header: React.FC = () => {

    const location = useLocation();

    return <>
        <nav className="navbar page-header navbar-expand-lg fixed-top navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="#"><img className="logo-image" src="/assets/images/logo.png" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                        </li>
                    </ul>
                    {/* 
                    {
                        location.pathname.toLowerCase() != "/menu" && <div className="d-flex me-auto">
                            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-success" type="submit">Search</button>
                        </div>
                    }
                    {
                        location.pathname.toLowerCase() == "/menu" && <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <IonButton color="warning" routerLink="/Menu#page-1">Page 1</IonButton>
                            </li>
                            <li className="nav-item">
                                <IonButton color="warning" routerLink="/Menu#page-2">Page 2</IonButton>
                            </li>
                        </ul>
                    } */}
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {/* <li className="nav-item">
                            <IonButton color="warning" routerLink="/">Order Now</IonButton>
                        </li> */}
                        <li className="nav-item">
                            <IonButton color="warning" routerLink="/Menu">Menu</IonButton>
                        </li>
                    </ul>
                    <div className="text-right">
                        <strong className="contact-number">
                            <a href="tel:8826737766" className="fs-6 text-warning" > <FontAwesomeIcon icon="phone-alt" /> 8826737766</a>
                            {/* <span className="fs-6 text-warning"> | </span> */}
                            <br />
                            <a href="tel:8826737766" className="fs-6 text-warning" ><FontAwesomeIcon icon="phone-alt" /> 8826747766</a>
                        </strong>
                    </div>
                </div>
            </div>
        </nav>
    </>
};

export default Header;