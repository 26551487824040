import React from "react";
import "./Footer.scss";

const Header: React.FC = () => {
    return <>
        <small>
            <div className="row  bg-light bg-gradient p-3">
                <div className="col-md-7 text-dark">Address : 34/2, Ground Floor, Main Devli Road, Khanpur, New Delhi-110080, India</div>
                <div className="col-md-5 text-dark text-end">Timings : 10:30am to 10:00pm</div>
            </div>
        </small>
    </>
};

export default Header;